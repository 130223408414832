/* Text appears animation */

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appear60 {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: .6;
    transform: translateY(0);
  }
}

.platform-text-appear{
  animation: appear 700ms ease-in-out forwards;
}

.platform-text-appear:nth-child(2){
  animation: appear60 700ms ease-in-out forwards;
  animation-delay: 100ms;
}
.platform-text-appear:nth-child(3){
  animation: appear 700ms ease-in-out forwards;
  animation-delay: 160ms;
}
.platform-text-appear:nth-child(4){
  animation: appear 700ms ease-in-out forwards;
  animation-delay: 200ms;
}
.platform-text-appear:nth-child(5){
  animation: appear 700ms ease-in-out forwards;
  animation-delay: 250ms;
}

.facilities-section .title-active h3{
  animation: slideandappear 700ms ease-in-out forwards;
}

.facilities-section .title-active .facilities-text p{
  animation: slideandappear 700ms ease-in-out forwards;
  animation-delay: 80ms;
}

.facilities-section .title-active .facilities-card{
  animation: slideandappear 700ms ease-in-out forwards;
  animation-delay: 80ms;
}

.feature-carousel-section .title-active h3{
  animation: slideandappear 700ms ease-in-out forwards;
}

.feature-carousel-section .title-active .feature-carousel-content>p{
  animation: slideandappear 700ms ease-in-out forwards;
  animation-delay: 80ms;
}

.card-on-scroll-section .title-active .swiperCards{
  animation: slideandappear 700ms ease-in-out forwards;
}

.why-cpoa-section .title-active h3{
  animation: slideandappear 700ms ease-in-out forwards;
}

.why-cpoa-section .title-active .mission-vision{
  animation: slideandappear 700ms ease-in-out forwards;
  animation-delay: 80ms;
}

.why-cpoa-section .title-active .mission-vision:nth-child(2){
  animation: slideandappear 700ms ease-in-out forwards;
  animation-delay: 160ms;
}

.winning-hearts-section .title-active h3{
  animation: slideandappear 700ms ease-in-out forwards;
}

.winning-hearts-section .title-active .winning-hearts-card{
  animation: slideandappear 700ms ease-in-out forwards;
  animation-delay: 80ms;
}

.coming-cards-section .title-active h3{
  animation: slideandappear 700ms ease-in-out forwards;
}

.coming-cards-section .title-active .coming-cards{
  animation: slideandappear 700ms ease-in-out forwards;
  animation-delay: 80ms;
}

@keyframes slideandappear {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Styling for Platform section */

/* .platform-section::after{
  content: url(../../assets//home/platform/rays.svg);
  position: absolute;
  top: -15%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
} */

/* Styling for Facilities section */

.facilities-section{
  background: url(../../assets/home/features/grid.svg), linear-gradient(0deg, #0A0000 0%, #0A0000 100%), linear-gradient(0deg, #1E2124 0%, #1E2124 100%), #FFF;
  background-repeat: no-repeat;
  background-position: top;
  /* background-size: cover; */
}

.marquee{
  /* flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around; */
  /* min-width: 100%; */
  animation: scroll-x 30s linear infinite;
}

@keyframes scroll-x {
  from {
    transform: translateX(0%);
  }
  to {
    /* transform: translateX(-111.4%); */
    transform: translateX(-116.4%);
  }
}

.gradient-overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(4, 0, 1, 1) 0%, #040001 10%, rgba(4, 0, 1, 0) 20%),
              linear-gradient(to left, rgba(4, 0, 1, 1) 0%, #040001 10%, rgba(4, 0, 1, 0) 20%);
  pointer-events: none; /* Allow clicks to go through the overlay */
}

.facilities-card{
  border-radius: 30.371px;
  border: 1px solid #ffdfdf49;
  background: linear-gradient(127deg, rgba(255, 81, 111, 0.10) 12.74%, rgba(255, 105, 105, 0.00) 122.89%);
  backdrop-filter: blur(47px);
}

.facilities-section .center-logo::before{
  content: 'Clients we cater';
  position: absolute;
  font-size: 14px;
  position: absolute;
  /* top: -25%; */
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  text-align: center;
  background: linear-gradient(90deg, #FFCB92 0%, #FFCB92 50%, #FFCB92 100%);
  background-clip: text;
  color: transparent;
}

.facilities-section .center-logo::after{
  content: 'Impact we create';
  position: absolute;
  font-size: 14px;
  position: absolute;
  /* bottom: -25%; */
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  text-align: center;
  background: linear-gradient(90deg, #FFCB92 0%, #FFCB92 50%, #FFCB92 100%);
  background-clip: text;
  color: transparent;
}

.left-pointer-line::before{
  content: url(../../assets/home/features/radiator-icon-fill.svg);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-75%);
  opacity: 0;
  animation: fadeInBeforeLeft 15s ease infinite;
}

.left-pointer-line::after{
  content: url(../../assets/home/features/radiator-icon-skeleton.svg);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-75%);
  opacity: 1;
  animation: fadeInAfterLeft 15s ease infinite;  
}


@keyframes fadeInBeforeLeft {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
}

@keyframes fadeInAfterLeft {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
}

.center-icon-skeleton{
  opacity: 1;
  animation: fadeInAfterCenter 15s ease infinite; 
}

.center-icon-fill{
  opacity: 0;
  animation: fadeInBeforeCenter 15s ease infinite; 
}

@keyframes fadeInAfterCenter {
  0% {
    opacity: 1;
  }
  35% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
}

@keyframes fadeInBeforeCenter {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
}



.right-pointer-line::after{
  content: url(../../assets/home/features/radiator-icon-skeleton.svg);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-25%);
  opacity: 1;
  animation: fadeInAfterRight 15s ease infinite; 
}

.right-pointer-line::before{
  content: url(../../assets/home/features/radiator-icon-fill.svg);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-25%);
  opacity: 0;
  animation: fadeInBeforeRight 15s ease infinite; 
}

@keyframes fadeInBeforeRight {
  0% {
    opacity: 0;
  }
  59%{
    opacity: 0;
  }
  70%{
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInAfterRight {
  0% {
    opacity: 1;
  }
  59%{
    opacity: 1;
  }
  70%{
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  /* 50% {
    opacity: 1;
  } */
}


.radiator-parent::before{
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  height: 100%;
  width: 1.016px;
  background-color: #FF6566;
  opacity: .2;
}

/* .radiator-parent::after{
  content: '';
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 1px;
  background-color: #FF6566;
  opacity: .2;
} */

.rotate-radiator{
  animation: rotate-radiator 15s linear infinite; 
  transform: translate(-50%,-50%);
  clip-path: polygon(0 0,93.3673469388% 0,93.3673469388% 25.1275510204%,50% 50.1275510204%,0 50.1275510204%);
}

.rotate-radiator::after{
  content: '';
  border-radius: 50%;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(267deg, rgba(234, 85, 86, 0.55) 13.23%, rgba(234, 85, 86, 0.00) 50%);
}


@keyframes rotate-radiator {
  0%{
    transform: translate(-50%,-50%) rotate(210deg);
  }
  100%{
    transform: translate(-50%,-50%) rotate(450deg);
  }
}

.features-dets:nth-child(2)::before{
  content: url(../../assets/home/features/small-dashhed-line.svg);
  position: absolute;
  transform: translateY(-100%);
}
.features-dets:nth-child(3)::before{
  content: url(../../assets/home/features/small-dashhed-line.svg);
  position: absolute;
  transform: translateY(-100%);
}

/* Pagination styling for second last section */

.swiper-pagination .swiper-pagination-bullet {
  background: #fff !important;
  opacity: 1 !important;
  height: 10px !important;
  width: 10px;
  transition: all 500ms ease-in-out;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #ea5455 !important;
  width: 70px !important;
  height: 10px !important;
  margin-top: 300px;
  border-radius: 8px;
}

/* Styling for cardOnScroll section */

.card-on-scroll .card-gradient{
    background: url(../../assets/home/cardOnScroll/grid-bg.svg),linear-gradient(103deg, #F8BEB2 0.63%, #FAE2D3 23.53%, #FFF 54.65%);
    background-size: cover;
    background-position: left;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}


/* Styling of winningHearts section */

.winning-hearts-card {
  /* border-radius: 30.371px; */
  border: 1px solid #ffdfdf;
  opacity: 0.9;
  background: linear-gradient(
      127deg,
      rgba(255, 81, 111, 0.1) 12.74%,
      rgba(255, 105, 105, 0) 122.89%
    ),
    linear-gradient(90deg, #1b0606 0.48%, #0b0000 99.89%), #1e2124;
  background-blend-mode: normal, color, normal;
  box-shadow: 0px 4px 16px 0px rgba(255, 141, 141, 0.25) inset;
  backdrop-filter: blur(47px);
}

/* Styling of featureCarousel section */


/* .image{
  border-radius: 1501.478px;
  opacity: 0.7;
  background: linear-gradient(180deg, rgba(252, 156, 81, 0.00) 62.98%, #FC9B51 97.25%);
  filter: blur(122.6500015258789px);
} */

.blur-gradient{
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.10);
  background: rgba(245, 245, 245, 0.15);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.20);
  overflow: hidden;
}

.blur-gradient::before{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 598.553px;
  opacity: 0.5;
  background: linear-gradient(90deg, #EE6E86 0%, #EFAA65 100%);
  filter: blur(164.79306030273438px);
}


.designFeatures {
  opacity: 0;
}
.designFeatures-active {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
}

.designFeatures-exit {
  opacity: 1;
}
.designFeatures-exit-active {
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.transition-text-wrapper :nth-child(1) {
  animation: appear 500ms ease-in-out forwards;
  animation-delay: 100ms;
}
.transition-text-wrapper :nth-child(2) {
  animation: appear 500ms ease-in-out forwards;
  animation-delay: 150ms;
}
.transition-text-wrapper :nth-child(3) {
  animation: appear 500ms ease-in-out forwards;
  animation-delay: 200ms;
}
.transition-text-wrapper :nth-child(4) {
  animation: appear 500ms ease-in-out forwards;
  animation-delay: 250ms;
}
.transition-text-wrapper :nth-child(5) {
  animation: appear 500ms ease-in-out forwards;
  animation-delay: 300ms;
}

.designFeatures-image-enter {
  filter: blur(20px);
  opacity: 0;
}
.designFeatures-image-enter-active {
  opacity: 1;
  filter: blur(0);
  transition: all 300ms ease-in-out;
}

.designFeatures-image-exit {
  filter: blur(0);
  opacity: 1;
}
.designFeatures-image-exit-active {
  transition: all 300ms ease-in-out;
  filter: blur(20px);
  opacity: 0;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Styling for CardOnScroll section */

.chart-div-mobile{
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.10);
  background: rgba(245, 245, 245, 0.15);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.20);
}


/* Styling for the section WhyCPOA  */

.animated-cards{
  border-radius: 30.371px;
  border: 1px solid #ffdfdf50;
  background: linear-gradient(180deg, rgba(255, 81, 111, 0.10) -28.64%, rgba(255, 105, 105, 0.00) 108.17%), linear-gradient(90deg, rgba(27, 6, 6, 0.00) 0.48%, rgba(11, 0, 0, 0.00) 99.89%), rgba(30, 33, 36, 0.00);
  background-blend-mode: normal, color, normal;
  box-shadow: 0px 4px 16px 0px rgba(255, 141, 141, 0.25) inset;
  backdrop-filter: blur(47px);
}

.logo-path-anim{
  stroke-dasharray: 100; /* Set to the total length of the path */
  stroke-dashoffset: 100; /* Set to the total length of the path */
  animation: LogoStrokeAnimation 10s both linear infinite ;
}

@keyframes LogoStrokeAnimation {
  0% {
    stroke-dashoffset: 100; /* Set to the total length of the path */
  }
  50% {
    stroke-dashoffset: 0; /* Set to the total length of the path */
  }
  100% {
    stroke-dashoffset: -100; /* Set to the total length of the path */
  }
}


@keyframes strokeAnimation {
  0% {
    stroke-dashoffset: 900; /* Set to the total length of the path */
  }
  50% {
    stroke-dashoffset: 0; /* Set to the total length of the path */
  }
  100% {
    stroke-dashoffset: -900; /* Set to the total length of the path */
  }
}

@keyframes strokeAnimationForThreeCircles {
  0% {
    stroke-dashoffset: 1000; /* Set to the total length of the path */
  }
  50% {
    stroke-dashoffset: 0; /* Set to the total length of the path */
  }
  100% {
    stroke-dashoffset: -1000; /* Set to the total length of the path */
  }
}


/* Apply the animation to the SVG path */
.path-anim {
  stroke-dasharray: 1000; /* Set to the total length of the path */
  stroke-dashoffset: 1000; /* Set to the total length of the path */
  animation: strokeAnimation 30s both linear infinite;
}

.circles-path-anim {
  stroke-dasharray: 1000; /* Set to the total length of the path */
  stroke-dashoffset: 1000; /* Set to the total length of the path */
  animation: strokeAnimationForThreeCircles 40s both linear infinite;
}
.circles-path-anim-reverse {
  stroke-dasharray: 1000; /* Set to the total length of the path */
  stroke-dashoffset: 1000; /* Set to the total length of the path */
  animation: strokeAnimationForThreeCircles 40s both linear reverse infinite;
}


@keyframes blink {
  0%, 100% {
    stroke-opacity: 1;
  }
  50% {
    stroke-opacity: 0;
  }
}

.path-anim-blink { /* Set to the total length of the path */
  animation: blink 2s infinite;
}

/* @keyframes scale {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
}

.path-anim-scale {
  animation: scale 5s infinite;
  transform-origin: center;
} */

.shine{
  width: 100%;
  height: 100%;
  position: absolute;
  /* float: right; */
  border-radius: 5px;
  background: linear-gradient(120deg, #e5e5e500 30%, #f0f0f04d 38%, #f0f0f038 
  40%, #e5e5e500 48%);
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 3s infinite;
}

@keyframes load89234 {
  100% {
    background-position: -100% 0;
  }
}

.custom-svg {
  width: 239px;
  height: 162px;
  position: relative;
}

.rect-container {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: linear-gradient(to bottom, #FFF6EE, #FFE3DD);
  border: 0.713799px solid linear-gradient(to bottom right, #E9EEF7, #5F43E9);
  box-sizing: border-box;
  animation: dash 2s linear infinite;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear alternate infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 822;
  }
  to {
    stroke-dashoffset: 0;
  }
}


.transparent-button:hover,.hero-btn:hover{
    box-shadow: 0px 0px 9.722px 1.768px rgba(234, 84, 85, 0.50), 0px 0px 3.535px 1.768px rgba(255, 57, 59, 0.89) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.transparent-button:focus{
    background: #EA5455;
    box-shadow: 0px 0px 9.722px 1.768px rgba(255, 255, 255, 0.12) inset, 0px 0px 3.535px 1.768px rgba(255, 57, 59, 0.89) inset;
}

.white-transparent-button:hover{
    color: #000;
    background: #FFF;
    box-shadow: 0px 0px 9.722px 1.768px rgba(255, 255, 255, 0.50), 0px 0px 3.535px 1.768px rgba(255, 255, 255, 0.89) inset;
}
/* h1 {
  color: #ea5556 !important ;
  font-size: 40px !important;
} */
/* h2 {
  color: #8d5555 !important;
  font-size: 40px !important;
} */

.markdown-wrapper table{
  margin-top: 20px;
  border: 1px solid #ffffff71;
  border-radius: 20px;
  overflow: hidden;
  font-family: regular;
  width: 100%;
}

.markdown-wrapper table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

.markdown-wrapper th, .markdown-wrapper td {
  border: 1px solid #ffffff71;
  text-align: left;
  padding: 10px 20px;
  border-collapse: collapse;
}

th:nth-child(1){
  border-top-left-radius: 20px;
}
th:nth-child(3){
  border-top-right-radius: 20px;
}

tr:nth-child(5) td:nth-child(1){
  border-bottom-left-radius: 20px;
}

tr:nth-child(5) td:nth-child(3){
  border-bottom-right-radius: 20px;
}

tr:nth-child(1) td:nth-child(1){
  border-bottom: none;
}
tr:nth-child(2) td:nth-child(1){
  border-top: none;
  border-bottom: none;
}
tr:nth-child(3) td:nth-child(1){
  border-top: none;
}

@media (max-width: 768px) {
  .markdown-wrapper table {
    width: auto;
    overflow-x: auto;
    display: block;
  }
  
  .markdown-wrapper th, .markdown-wrapper td {
    border: 1px solid #ffffff71;
    text-align: left;
    padding: 20px 15px;
    font-size: 14px;
    white-space: nowrap;
  }
}

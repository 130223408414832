@font-face {
  font-family: 'regular';
  src: url('assets/fonts/Inter-Regular.woff2');
}

@font-face {
  font-family: 'bold';
  src: url('assets/fonts/Inter-Bold.woff2');
}

@font-face {
  font-family: 'light';
  src: url('assets/fonts/Inter-Light.woff2');
}

@font-face {
  font-family: 'medium';
  src: url('assets/fonts/Inter-Medium.woff2');
}

@font-face {
  font-family: 'semibold';
  src: url('assets/fonts/Inter-SemiBold.woff2');
}

.markdown-wrapper {
  @apply text-base xl:text-lg
}

.markdown-wrapper h5 {
  @apply font-bold pt-8;
}

.markdown-wrapper p {
  @apply pt-3 font-regular;
}

.markdown-wrapper a {
  @apply text-blue underline font-regular;
}

.markdown-wrapper ol {
  @apply pl-8 list-outside list-decimal;
  /* list-style: inside;
  list-style-type: decimal;
  padding-left: 0.5rem; */
  /* counter-reset: start -1; */
}

.markdown-wrapper ul {
  @apply pl-8 list-outside list-disc;
}

.markdown-wrapper ol li,
.markdown-wrapper ul li {
  @apply pb-3 font-regular;
}
.dropdown-options::-webkit-scrollbar{
    width: 2px;
}
.dropdown-options::-webkit-scrollbar-track{
    background-color: #241A1A;
}
.dropdown-options::-webkit-scrollbar-thumb{
    background-color: gray;
}

.contact-us-popup .close{
    fill: #3C1D1A;
    box-shadow: 0px 0.2px 0.2px 0px #AB8A88 inset;
    filter: drop-shadow(0px 0px 0px #4F2F2D) drop-shadow(0px 0.2px 0.6px rgba(255, 255, 255, 0.40));
}
  

.contact-us-popup-scroll::-webkit-scrollbar {
    display: none;
}
 